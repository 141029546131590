import React from 'react';
import Main from '../containers/Layout';
import aboutPageContent from '../../content/pages/about.yml';
import AboutForToolkitContainer from '@/containers/toolkit/AboutForToolkitContainer';

export interface AboutForToolkitPageContent {
  viewport1: {
    pageTitle: string;
    title2: string;
    title3: string;
    title4: string;
    description1: string;
    description2: string;
    description3: string;
    description4: string;
    description5: string;
    description6: string;
    description6Items: {
      item: string;
    }[];
    description7: string;
    cardsTitle: string;
    whoIsItForCards: {
      title1: string;
      title2: string;
      title3: string;
      title4?: string;
      description: string;
    }[];
  };
  viewport2: {
    title1: string;
    title2: string;
    teamCards: {
      avatar: string;
      name: string;
      position: string;
      socials: {
        url: string;
        type: SocialType;
      }[];
    }[];
  };
  lowerText: {
    lowerTitle1: string;
    lowerTitle2: string;
    lowerText1: string;
    lowerText2: string;
    button: {
      label: string;
      url: string;
    };
  };
}
const About: React.FunctionComponent = () => {
  const { viewport1, viewport2, lowerText } =
    aboutPageContent as unknown as AboutForToolkitPageContent;
  return (
    <Main>
      <AboutForToolkitContainer
        type="toolkit"
        content={{ viewport1, viewport2, lowerText }}
      />
    </Main>
  );
};

export default About;
